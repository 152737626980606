import React from "react";

// About page

import About from "./about/index";

const Index = () => {
  return (
    <>
      <About />
    </>
  );
};

export default Index;
