import React from "react";
import Layout from "../../components/layouts/index";
import Seo from "../../components/Seo";

// Sections

import Intro from "./sections/intro";
import Qualifications from "./sections/qualifications/qualifications";

// Carousels

const About = () => {
  return (
    <Layout>
      <Seo title="About" />
      <div id="about-wrapper">
        <Intro />
        <Qualifications />
      </div>
    </Layout>
  );
};

export default About;
